const operatingEnvironmentDomainsMap = new Map<string, string[]>([
  ['localhost:1337', ['docker.for.mac.localhost:8090']],
  ['api.mttr-dev2.net', ['www.mttr-dev.net', 'app-backend.mttr-dev2.net']],
  ['api-main.mttr-dev2.net', ['app-backend-main.mttr-dev2.net']],
  ['api-deltav.mttr-dev2.net', ['app-backend-deltav.mttr-dev2.net']],
  [
    'api.mttr-stage2.net',
    ['www.mttr-stage.net', 'app-backend.mttr-stage2.net'],
  ],
  ['api-main.mttr-stage2.net', ['app-backend-main.mttr-stage2.net']],
  ['api-deltav.mttr-stage2.net', ['app-backend-deltav.mttr-stage2.net']],
  ['api.mttr-prod2.net', ['www.mttr-edge.net', 'app-backend.mttr-prod2.net']],
  [
    'api-main.mttr-prod2.net',
    ['prod.mttr.net', 'app-backend-main.mttr-prod2.net'],
  ],
  ['api-deltav.mttr-prod2.net', ['app-backend-deltav.mttr-prod2.net']],
  ['api-tc.mttr-prod2.net', ['app-backend-tc.mttr-prod2.net']],
  ['api-cmd.mttr-prod2.net', ['app-backend-cmd.mttr-prod2.net']],
]);

const getOperatingEnvironments = (hasuraDomain: string) => {
  const backendDomains: string[] =
    operatingEnvironmentDomainsMap.get(hasuraDomain) || [];

  return backendDomains;
};

export default getOperatingEnvironments;
